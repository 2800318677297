import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/layout/Layout'
import Header from '../components/Header'
import Info from 'gatsby-theme-psg/src/components/info/TopicList'
import Contact from 'gatsby-theme-psg/src/components/Contact'
import Section from 'gatsby-theme-psg/src/components/layout/section/Section'
import Lead from '../components/Lead';

export const InfoPageTemplate = ({ title, content }) => {
  return (
    <React.Fragment>
      <Header background='rgba(255,120,0,0.85)'>
        <Lead title={title} content={content}/>
      </Header>
      <div className="section-info">
        <Section title="Tickets" className="section-info-tickets">
          <Info category="Tickets"></Info>
        </Section>
        <Section title="Algemene info" className="section-algemene-info">
          <Info category="Algemene info"></Info>
        </Section>
        <Section title="Vervoer" className="section-vervoer">
          <Info category="Vervoer"></Info>
        </Section>
        <section className="section-contact">
          <div className="container pb-5">
            <Contact />
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

const InfoPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
      <InfoPageTemplate title={post.frontmatter.title} content={post.html} />
    </Layout>
  )
}

export default InfoPage

export const pageQuery = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
