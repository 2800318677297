import React from 'react'
import stars from '../../../../img/stars.png'

export default ({ title }) => {
  return (
    <>
      <img src={stars} alt="stars" />
      <h2 className="text-center">
        <span className="section-heading">
          {title}
        </span>
      </h2>
    </>
  )
}